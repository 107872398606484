import italy from './italy'
import spain from './spain'
import portugal from './portugal'
import brazil from './brazil'
import colombia from './colombia'
const translate =  {
    '+34':spain,
    '+39': italy,
    '+55': brazil,
    '+351':portugal,
    '+57':colombia
}
export default translate