import axios from "axios";
export interface CountryResponse {
    data:{
    ip?: string;
    network?: string;
    version?: string;
    city?: string;
    region?: string;
    region_code?: string;
    country?: string;
    country_name?: string;
    country_code?: string;
    country_code_iso3?: string;
    country_capital?: string;
    country_tld?: string;
    continent_code?: string;
    in_eu?: boolean;
    postal?: string;
    latitude?: number;
    longitude?: number;
    timezone?: string;
    utc_offset?: string;
    country_calling_code: "+34" | "+39" |"+55"| "+351"|"+57";
    currency?: string;
    currency_name?: string;
    languages?: string;
    country_area?: number;
    country_population?: number;
    asn?: string;
    org?: string;
}
}
export async function getCountry():Promise<CountryResponse['data']> {
    const country:CountryResponse = await axios.get('https://ipapi.co/json/')
    return country?.data
}